<template>
  <v-card
    id="define-map"
    flat
    min-height="550"
    min-width="550"
    max-height="900"
    max-width="1200"
  >
    <v-card
      elevation="8"
      min-height="500"
      min-width="500"
      max-height="800"
      max-width="1200"
    >
      <div
        id="map"
        class="map"
      />
    </v-card>

    <v-card-actions>
      <v-btn
        dark
        color="deep-orange accent-3"
        @click="clearMap"
      >
        Clear
      </v-btn>
      <v-spacer />
      <v-btn
        color="primary"
        @click="getFullDraw"
      >
        Confirm
      </v-btn>
    </v-card-actions>
    <v-alert
      :value="alert"
      color="red accent-4"
      dark
      transition="scale-transition"
    >
      Draw something before, please.
    </v-alert>
  </v-card>
</template>

<script>
import 'ol/ol.css';
import { Draw, Modify, Snap } from 'ol/interaction';
import Map from 'ol/Map';
import View from 'ol/View';
import { OSM, Vector as VectorSource } from 'ol/source';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import WKT from 'ol/format/WKT';
import MultiPolygon from 'ol/geom/MultiPolygon';
import Feature from 'ol/Feature';

import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      raster: undefined,
      source: undefined,
      vector: undefined,
      map: undefined,
      modify: undefined,
      snap: undefined,
      editingDraw: '',
      fullDraw: '',
      WKTObject: new WKT(),

      alert: false
    };
  },
  computed: {
    ...mapState(['domain'])
  },
  async mounted() {
    await this.initiateMap();

    // se il numero di features è  più di una, rendi inseribili
    // altri poligoni, altrimenti solo modifica
    // if (this.domain.isModifingDomain == false) {
    //   this.addInteraction();
    // }
    this.addInteraction();
  },

  methods: {
    ...mapActions(['storeDomainPolygon']),
    initiateMap() {
      this.raster = new TileLayer({
        source: new OSM()
      });

      this.source = new VectorSource({ wrapX: false });

      this.vector = new VectorLayer({
        source: this.source
      });

      this.map = new Map({
        layers: [this.raster, this.vector],
        target: 'map',
        view: new View({
          projection: 'EPSG:4326',
          center: [12.282715, 41.701948],
          zoom: 5.7
        })
      });

      if (this.domain.isModifingDomain == true) {
        this.source.addFeature(
          new Feature(this.WKTObject.readGeometry(this.domain.polyString))
        );
      }

      this.modify = new Modify({ source: this.source });
      this.map.addInteraction(this.modify);
    },
    addInteraction() {
      this.editingDraw = new Draw({
        source: this.source,
        type: 'Polygon'
      });

      this.snap = new Snap({ source: this.source });
      this.map.addInteraction(this.snap);

      this.map.addInteraction(this.editingDraw);
    },

    async getFullDraw() {
      const features = this.source.getFeatures();

      for (let i = 0;i < features.length;i++) {
        if (i === 0) {
          if (features[i].getGeometry().getType() == 'MultiPolygon') {
            this.fullDraw = features[i].getGeometry();
          } else {
            this.fullDraw = new MultiPolygon(features[i].getGeometry());
            this.fullDraw.appendPolygon(features[i].getGeometry());
          }
        } else {
          this.fullDraw.appendPolygon(features[i].getGeometry());
        }
      }

      if (features.length > 0) {
        this.storeDomainPolygon(this.WKTObject.writeGeometry(this.fullDraw));
        this.alert = false;

        this.emitConfirm();
      } else {
        this.alert = true;
      }
    },

    clearMap() {
      this.source.clear();
      this.fullDraw = '';
    },

    emitConfirm() {
      this.$emit('confirmPolygon');
    }
  }
};
</script>

<style lang="scss" scoped>
.map {
  position: absolute;
  margin-left: 0px;
  margin-right: 0px;
  padding: 0;
  height: 100%;
  width: 100%;
}
</style>
